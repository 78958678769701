<template>
  <div :class="classObject">
    <div id="timer" class="timer">
      <div class="timer-number">
        <span class="days timer-time"></span>
        <span class="timer-text">Дней</span>
      </div>
      <div class="timer-separator">:</div>
      <div class="timer-number">
        <span class="hours timer-time"></span>
        <span class="timer-text">Часов</span>
      </div>
      <div class="timer-separator">:</div>
      <div class="timer-number">
        <span class="minutes timer-time"></span>
        <span class="timer-text">Минут</span>
      </div>
      <div class="timer-separator">:</div>
      <div class="timer-number">
        <span class="seconds timer-time"></span>
        <span class="timer-text">Секунд</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from "../../helpers";

export default {
  props: ["parentClass", "timer"],
  data() {
    return {
      classObject: {
        timer: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
  mounted() {
    function getTimeRemaining(endtime) {
      var t = Date.parse(endtime) - Date.parse(new Date());
      var seconds = Math.floor((t / 1000) % 60);
      var minutes = Math.floor((t / 1000 / 60) % 60);
      var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      var days = Math.floor(t / (1000 * 60 * 60 * 24));

      return {
        total: t,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    }

    function initializeClock(id, endtime) {
      var clock = document.getElementById(id);
      var daysSpan = clock.querySelector(".days");
      var hoursSpan = clock.querySelector(".hours");
      var minutesSpan = clock.querySelector(".minutes");
      var secondsSpan = clock.querySelector(".seconds");

      function updateClock() {
        var t = getTimeRemaining(endtime);

        if (t.total <= 0) {
          document.getElementById("timer").className = "hidden";
          document.getElementById("deadline-message").className = "visible";
          clearInterval(timeinterval);
          return true;
        }

        daysSpan.innerHTML = t.days;
        hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
        minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
        secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);
      }

      updateClock();
      var timeinterval = setInterval(updateClock, 1000);
    }

    var deadline;

    if (new Date() < new Date(this.timer)) {
      deadline = new Date(this.timer);
    } else {
      if (getCookie("timer")) {
        deadline = getCookie("timer");
      } else {
        let dateCurrent = new Date();
        dateCurrent.setDate(dateCurrent.getDate());
        dateCurrent.setHours(dateCurrent.getHours() + 72, 0, 0, 0);

        document.cookie = "timer=" + dateCurrent.toUTCString() + "; expires=" + dateCurrent.toUTCString();

        deadline = dateCurrent;
      }
    }

    initializeClock("timer", deadline);
  },
};
</script>

<style>
.timer {
  display: flex;
  flex-direction: row;
  width: 378px;
  height: 87px;
}

.timer-number {
  padding: 5px;
  text-align: center;
}

.timer-time {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  color: #36b9d6;
}

.dark .timer-time {
  color: #36b9d6;
}

.timer-text {
  display: block;
  padding-top: 10px;
  font-size: 16px;

  color: var(--title2-color-white);
}

.dark .timer-text {
  color: var(--title2-color-dark);
}

.timer-separator {
  margin: 5px 10px 0 10px;

  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;

  color: #36b9d6;
}

.dark .timer-separator {
  color: #36b9d6;
}

.deadline-message {
  display: none;
  font-size: 24px;
  font-style: italic;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

@media (max-width: 1599.98px) {
  .timer {
    width: 384px;
    height: 74px;
  }

  .timer-time {
    font-size: 40px;
    line-height: 47px;
  }

  .timer-text {
    font-size: 14px;
  }

  .timer-separator {
    font-size: 40px;
    line-height: 47px;
  }
}

@media (max-width: 1199.98px) {
  .timer {
    width: 314px;
    height: 67px;
  }

  .timer-time {
    font-size: 34px;
    line-height: 40px;
  }

  .timer-separator {
    font-size: 34px;
    line-height: 40px;
  }
}

@media (max-width: 991.98px) {
  .timer {
    width: 277px;
    height: 60px;
  }

  .timer-time {
    font-size: 28px;
    line-height: 32px;
  }

  .timer-separator {
    font-size: 28px;
    line-height: 32px;
  }
}

@media (max-width: 767.98px) {
  .timer {
    width: 286px;
    height: 63px;
  }

  .timer-time {
    font-size: 30px;
    line-height: 35px;
  }

  .timer-separator {
    font-size: 30px;
    line-height: 35px;
  }
}
</style>
